<template>
  <div class='canvasContainer'>

    <canvas
      id='nameC'
      ref='nameC'
      :height='wh'
      :width='ww'
      @mousemove='this.handleCursor'
    >
    </canvas>

  </div>
</template>





<script>
  export default {
    data() {
      return {
        ww: null,
        wh: null,
        particles: [],
        count: 0,
        radius: 1,
        mouse: {
          x: null,
          y: null,
        },
        img: null,
        
        colors: [
          '#333138', // grey,
        ],
        canvas: null,
        ctx: null,

        text: 'Andrew Oswald'
      }
    },





    methods: {
      // Initializes particles 
      initParticles() {
        this.ctx.clearRect(0, 0, this.ww, this.wh);
        // this.ctx.font = `bold 80px Oswald`;
        this.ctx.font = `bold 90px Quicksand`;
        this.ctx.textAlign = 'center',
        this.ctx.fillStyle = 'black'
        this.ctx.fillText(this.text, this.ww/2, this.wh/3);

        this.img = this.ctx.getImageData(0, 0, this.ww, this.wh).data;

        this.ctx.clearRect(0, 0, this.ww, this.wh); 
        this.ctx.globalCompositeOperation = 'screen';

        this.particles = [];

        console.log(this.ww + ' ' + this.wh);

        for (var i = 0; i < this.ww; i += Math.round(this.ww/450)) {
          for (var n = 0; n < this.wh; n += Math.round(this.ww/450)) {
            if ( this.img[ ((i + n * this.ww) * 4) + 3 ] > 150 ) {
              this.particles.push(this.getParticle(i, n));
            }

          }
        }

        this.count = this.particles.length;

        this.update();
      },



      // Creates particle to be added to particles array. 
      getParticle(x, y) {
        var p = {};

        // p.x =  Math.random() * this.ww; default
        // p.y =  Math.random() * this.wh; default
        p.x =  x;
        p.y =  y;
        p.dest = {
          x : x,
          y: y
        };
        p.r = Math.random() * 2 + 1;
        // p.vx = ( Math.random() - 0.5 ) * 20; default
        p.vx = ( Math.random() - 0.5 ) * 0;
        // p.vy = ( Math.random() - 0.5 ) * 20; default
        p.vy = ( Math.random() - 0.5 ) * 0;
        p.accX = 0;
        p.accY = 0;
        // p.friction = Math.random() * 0.05 + 0.94; default
        p.friction = Math.random() * 0.05 + .82;

        p.color = this.colors[ Math.floor( Math.random() * ( this.colors.length ) ) ];

        return p;
      },



      // Renders particles.
      update() {
        requestAnimationFrame(this.update);

        this.ctx.clearRect(0, 0, this.ww, this.wh);

        for (var i = 0; i < this.particles.length; i++) {
          var p = this.particles[i],
              a,
              b,
              dist;

          // p.accX = (p.dest.x - p.x)/1000; default
          p.accX = (p.dest.x - p.x)/100;
          // p.accY = (p.dest.y - p.y)/1000; default
          p.accY = (p.dest.y - p.y)/100;
          p.vx += p.accX;
          p.vy += p.accY;
          p.vx *= p.friction;
          p.vy *= p.friction;

          p.x += p.vx;
          p.y +=  p.vy;

          this.ctx.fillStyle = p.color;
          this.ctx.beginPath();
          this.ctx.arc(p.x, p.y, p.r, Math.PI * 2, false);
          // this.ctx.fillRect(p.x, p.y, p.r, p.r); 
          this.ctx.fill();

          a = p.x - this.mouse.x;
          b = p.y - this.mouse.y;

          dist = Math.sqrt( a * a + b * b );

          // if ( dist < ( this.radius * 70 ) ) { default
          if ( dist < ( this.radius * 50 ) ) {
            p.accX = ( p.x - this.mouse.x ) / 10;
            // p.accX = ( p.x - this.mouse.x ) / 100; // default
            p.accY = ( p.y - this.mouse.y ) / 10;
            // p.accY = ( p.y - this.mouse.y ) / 100; // default
            p.vx += p.accX;
            p.vy += p.accY;
          }
        }
      },



      // Tracks cursor position.
      handleCursor(e) {
        this.mouse.x = e.clientX;
        this.mouse.y = e.clientY;
      },



      // Measures window and assign values to data.
      handleResize() {
        this.ww = window.innerWidth;
        this.wh = window.innerHeight;
        this.$nextTick(() => {
        this.initParticles();
        })
      } 
    },





    mounted() {
      // this.canvas = document.querySelector('#nameC');
      this.canvas = this.$refs.nameC;
      this.ctx = this.canvas.getContext('2d');

      this.$nextTick(() => {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
      })
    },





    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    }
  }
</script>





<style scoped>
/* canvas {background: #555;} */
  .canvasContainer {
    position: absolute;
    height: 100vh;
    width: 100vw;
    /* background: #141216; */
    overflow: hidden;
  }
</style>