<template>
  <section class="projectsSection">

    <div
      v-for="side in sections"
      :key="side" 
      class="half"
      :class="side"
    >
      <div
        class="color"
        v-for="color in colors"
        :key="color.label"
        :style="{ height : 'calc(%100 / ' + colors.length + ')'}"
      >
        <div class="label">
          {{ color.label }}
        </div>

        <div class="swatch">
          <div 
            class="block"
            v-for="block in color.arr"
            :key="block"
            :style="{ background : block,
            width : 'calc(100% / ' + color.arr.length + ')' }"
          >
            <span>
              {{ block }}
            </span>
          </div>
        </div>
      </div>
    </div>
  
  </section>
</template>





<script>
export default {
  data() {
    return {
      sections: [
        'lt',
        'dk'
      ],
      colors: [
        {
          label: 'pinks', 
          arr: [
            '#efd2d2',        
            '#e5d1da',
            '#e9dde2',
            '#f6cece',
            '#f1bab4',
            '#fcbdc2',
            '#e489f8',
            '#f13f89'
          ]
        },
        {
          label: 'pink redish',
          arr: [
            '#fb6982',
            '#ee5060',
            '#fd2e62',
            '#bc3c6b',
            '#fb5080',
            '#fe4c5e',
            '#f43b7b'
          ]
        },
        {
          label: 'greens',
          arr: [
            '#bdf165',
            '#adf950',
            '#81fb35',
            '#9bfdab',
            '#03ef1e',
            '#87f7a3',
            '#8ff5c3',
            '#cafde0'
          ]
        },
        {
          label: 'blues',
          arr: [
            '#70e9db',
            '#9dfaee',
            '#a9e3e2',
            '#04b2b2',
            '#3be9db',
            '#364dcd',
          ]
        },
        {
          label: 'purples',
          arr: [
            '#af77de',
            '#912ed8',
            '#9d83f4',
            '#620672',
            '#4c1fa7',
            '#5400a7', 
            '#48166f' 
          ]
        },
        {
          label: 'dark grey blue green',
          arr: [
            '#364149',
            '#373946',
            '#2a4955',
            '#184e57',
            '#345067',
            '#224b5d',
            '#384148',
            '#1b4e53',
          ],
        }
      ],
    }
  }
}
</script>





<style scoped>
  .projectsSection {
    display: flex;
    height: 100vh;
    width: 100vw;
  }

  .half {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    width: 50%;
    height: 100%;
    padding: 3rem;
  }

  .lt {
    background: #fff;
    align-items: flex-end;
  }

  .dk {
    background: #555;
  }

  .color {
    display: flex;
    flex-direction: column;
    /* gap: .5rem; */
    width: 100%;
  }

  .label {
    font-size: 1rem;
    font-weight: bold;
  }

  .lt .label {
    text-align: right;
  }

  .swatch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 2.5rem;
  }

  .lt .swatch {
    flex-direction: row-reverse;
  }

  .block {
    height: 100%;
  }





</style>