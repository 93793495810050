export default {
  namespaced: true,
  
  state: {
    patterns: [
      {
        name: 'abstract_1', // memphis_1
        height: '70',
        width: '70',
        pathColorType: 'stroke',
        paths: [
          'M-4.8 4.44L4 16.59 16.14 7.8M32 30.54l-13.23 7.07 7.06 13.23M-9 38.04l-3.81 14.5 14.5 3.81M65.22 4.44L74 16.59 86.15 7.8M61 38.04l-3.81 14.5 14.5 3.81',
  
          'M59.71 62.88v3h3M4.84 25.54L2.87 27.8l2.26 1.97m7.65 16.4l-2.21-2.03-2.03 2.21m29.26 7.13l.56 2.95 2.95-.55',
  
          'M58.98 27.57l-2.35-10.74-10.75 2.36M31.98-4.87l2.74 10.65 10.65-2.73M31.98 65.13l2.74 10.66 10.65-2.74',
  
          'M8.42 62.57l6.4 2.82 2.82-6.41m33.13-15.24l-4.86-5.03-5.03 4.86m-14-19.64l4.84-5.06-5.06-4.84'
        ],
        stroke: '1',
        scale: '2',
        rotate: '0',
        opacity: '1'
      },
      {
        name: 'hex_1',
        height: '85',
        width: '73.612',
        pathColorType: 'fill',
        paths: [
          'M36.8-18.62L47.57 0 58.4-18.6zM47.57 0L36.7 18.62h21.6zM0 2.63l10.75 18.62L21.59 2.64zm10.75 18.62L-.1 39.87h21.6zM73.61 2.63l10.75 18.62L95.21 2.64zm10.75 18.62L73.52 39.87h21.6zm-47.55 2.63L47.56 42.5 58.4 23.9zM47.56 42.5L36.7 61.12h21.6zM0 45.13l10.75 18.62 10.84-18.61zm10.75 18.62L-.1 82.37h21.6zm62.86-18.62l10.75 18.62 10.85-18.61zm10.75 18.62L73.52 82.37h21.6zm-47.55 2.63L47.56 85 58.4 66.4zM47.56 85L36.7 103.62h21.6z',

          'M58.4-18.61L47.56 0h21.59L58.4-18.6zM47.56 0h-21.6l10.75 18.62L47.56 0zM21.59 2.64L10.75 21.25h21.6L21.58 2.65zM10.75 21.25h-21.6L-.1 39.87l10.85-18.62zm52.02 0l10.75 18.62 10.84-18.62h-21.6zm-4.37 2.64L47.56 42.5h21.59L58.4 23.9zM47.56 42.5h-21.6l10.75 18.62L47.56 42.5zm-25.97 2.64L10.75 63.75h21.6L21.58 45.15zM10.75 63.75h-21.6L-.1 82.37l10.85-18.62zm52.02 0l10.75 18.62 10.84-18.62h-21.6zm-4.37 2.64L47.56 85h21.59L58.4 66.4zM47.56 85h-21.6l10.75 18.62L47.56 85z',

          'M58.3 103.62l10.85-18.61h-21.6zM25.97 85h21.6L36.8 66.38zm32.35-66.38L69.15.01h-21.6zM25.96 0h21.6L36.8-18.62zm36.8 63.75h21.6L73.61 45.13zM21.5 39.87l10.84-18.61H10.75zm-32.34-18.62h21.59L0 2.63zm95.2 0L73.61 2.63 62.77 21.25zm-73.61 42.5L21.5 82.37l10.84-18.61zM0 45.13l-10.85 18.62h21.6zm10.33 20.45l-.37-.56-.15.26zM46.1 86.23l.52.3.15-.26zm23.05-43.72h-21.6l10.76 18.61zm-21.6 0L36.82 23.87 25.96 42.5z'
        ],
        scale: '2',
        rotate: '0',
        opacity: '1'
      },
      {
        name: 'circles_1',
        height: '65',
        width: '65',
        pathColorType: 'fill',
        paths: [
          'M25.5 6.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm39 13a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm0 13a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-39 0a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm13 26a6 6 0 1 1-12 0 6 6 0 0 1 12 0z',
          'M64.5 45.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm0-39a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-13 13a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm0 26a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-26 13a6 6 0 1 1-12 0 6 6 0 0 1 12 0z',
          'M51.5 32.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-13-13a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm0-13a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-26 39a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm0 13a6 6 0 1 1-12 0 6 6 0 0 1 12 0z',
          'M51.5 58.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm-39-52a6 6 0 1 1-12 0 6 6 0 0 1 12 0Zm0 26a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm26 13a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm-13-26a6 6 0 1 1-12 0 6 6 0 0 1 12 0z'
        ],
        scale: '2',
        rotate: '0',
        opacity: '1'
      },
      {
        name: 'cubes_1',
        height: '40',
        width: '69.141',
        pathColorType: 'stroke',
        paths: [
          'M69.212 40H46.118L34.57 20 46.118 0h23.094l11.547 20zM57.665 60H34.57L23.023 40 34.57 20h23.095l11.547 20zm0-40H34.57L23.023 0 34.57-20h23.095L69.212 0zM34.57 60H11.476L-.07 40l11.547-20h23.095l11.547 20zm0-40H11.476L-.07 0l11.547-20h23.095L46.118 0zM23.023 40H-.07l-11.547-20L-.07 0h23.094L34.57 20z'
        ],
        stroke: '2',
        scale: '2',
        rotate: '0',
        opacity: '1'
      }
    ],
  },

  getters: {},

  mutations: {},

  actions: {}
};