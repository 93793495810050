<template>
  <div id="app">

    <nav-menu />

    <router-view/>

  </div>
</template>

<script>
import navMenu from './components/navMenu.vue'

export default {
  components: { 
    navMenu 
  },
  
}
</script>

<style>
@font-face {
  font-family: 'Oswald';
  src: local('Oswald'),
       url(./assets/fonts/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'),
       url(./assets/fonts/Quicksand-Regular.ttf) format('truetype');
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

/* 
--sea-green-crayola: #33ffcfff;
--blue-jeans: #49b6ffff;
--purple: #7209b7ff;
--ultra-red: #ff6978ff;
--charcoal: #383f51ff;
 */

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* #nav {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  z-index: 1;
} */

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.flexC {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
