<template>
  <header class="navContainer">

    <router-link to="/">home</router-link> 
    <router-link to="/projects">projects</router-link>
    <router-link to="/about">about</router-link>
    <router-link to="/contact">contact</router-link>

  </header>
</template>

<script>
export default {
  name: 'Menu',

  props: {},
}
</script>

<style scoped>
.navContainer {
  position: fixed;
  top: 3rem;
  right: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  z-index: 2;
}

.navContainer a {
  font-weight: bold;
  text-decoration: none;
  color: var(--color1);
}
</style>
