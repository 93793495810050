<template>
  <section class='skillsSection'>

    <canvasbg />

  </section>
</template>





<script>
import canvasbg from '../../components/canvasbg.vue'

export default {
  components: {
    canvasbg
  },

  data() {
    return {
      skills: [

      ]
    }
  }











}
</script>





<style scoped>
  .skillsSection {
    height: 100vh;
  }

</style>