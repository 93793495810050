<template>
  <div 
    class="landing"
    @scroll="handleScroll"
  >

    <intro
      :w ="w" 
    />

    <skills />

    <projects />

    <contact />

  </div>
</template>

<script>
import intro from '../components/landingSections/introSection.vue'
import skills from '../components/landingSections/skillsSection.vue'
import projects from '../components/landingSections/projectsSection.vue'
import contact from '../components/landingSections/contactSection.vue'

export default {
  name: 'Home',



  components: {
    intro,
    skills,
    projects,
    contact
  },



  data() {
    return {
      mouse: {
        x: null,
        y: null
      },

      w: {
        h: null,
        w: null
      },

      scroll: null
    }
  },



  methods: {
    // Tracks cursor position.
    handleCursor(e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
    },

    handleResize() {
      this.w.h = window.innerHeight;
      this.w.w = window.innerWidth;
    },

    handleScroll(e) {
      console.log(e);
    },
  },



  created() {
    window.addEventListener('resize', this.handleResize());
  },



  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize());
  }
}
</script>





<style>
  .landing {
    --bgColor1: #e3f2fd;
    --color1: #363d4a;
    --color2: #F61067;
    --color3: #3185FC;
    --color4: #531CB3;
    /* --bgColor3: #1d435d; */
    /* --color1: #e7dfe7; */
    /* --color2: #f7c4c2; */
    /* #3f3f4f gunpowder */
  }

</style>
