<template>
  <section 
    class='landingIntro'
    @mousemove='handleCursor'
  >

    <div class="introBackground">
      <div class="stage">
        <div 
          class='row'
          v-for='(row, index) in this.rows'
          :key='index'
        >
          <div
            class='cube'
            v-for='(cube, index) in row'
            :key='index'
            :style="{ backgroundColor : cube, '--i' : index }"
          >
          </div>
        </div>

      </div>
    </div>

    <div class="textContainer">
      <p>
        Hi, my name is 
      </p>

      <h1 class='name'>
        <span
          v-for='(letter, index) in letters'
          :key='index'
          class='letter'
        >
         {{ letter }}
        </span>
       </h1>

      <p>
        I'm a Frontend Web Developer.
      </p>
    </div>

    <!-- <div class="textContainer">
      <svg viewbox="0 0 1200 200">
        <text class="name" x="50%" y="50%" dy="-.35em" text-anchor="middle">
          Andrew Oswald
        </text>
        <text class="work" x="50%" y="50%" dy="-4em" text-anchor="middle">
          Web Developer
        </text>
      </svg>
    </div> -->
  </section>
</template>





<script>
export default {
  data() {
    return {
      name: 'Andrew Oswald.',

      mouse: {
        x: null,
        y: null
      },

      
    }
  },





  props: {
    w: {
      type: Object
    }
  },





  computed: {
    letters() { 
      return Array.from(this.name); 
    },
    


    rows() {
      var arr = [],
          cnt = 9,
          colors = [
            '#3bfbeb',
            '#8e73f7',
            '#e0c3ff',
          ];

      for (var i = 0; i < cnt; i++) {
        var row = [];

        for (var n = 0; n < cnt; n++) {
          var c = colors[(i + n) % 3];

          row.push(c);
        }

        arr.push(row);
      } 

      return arr;
    }
  },





  methods: {
    handleCursor(e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
    },


  },





  mounted() {
  },





  beforeDestroy() {
  }
}
</script>





<style scoped>
  .landingIntro {
    position: relative;
    height: 100vh;
    width: 100vw;
    /* background: var(--bgColor2); */
    /* background: linear-gradient(-45deg, #eeb2a0, #fcbed5, #ace1f5, #b5faea); */
    /* background: linear-gradient(-45deg, #ee7752, #ff9bc1, #23a6d5, #23d5ab); */
    background-size: 400% 400%;
    /* animation: gradientShift 15s ease infinite; */
    overflow: hidden;
  }

  .introBackground {
    position: absolute;
    inset: 0px;
    transform-style: preserve-3d;
    perspective: 1100px;
  }

  .stage {
    position: absolute;
    right: 9rem;
    bottom: 5rem;
    width: fit-content;
    transform-style: preserve-3d;
    transform: rotateX(60deg) rotateZ(30deg);
  }

  .row {
    display: flex;
    transform-style: preserve-3d;
    /* transform: scale(.5); */
  }

  .cube {
    --size: 2.5rem;
    --offset: calc(var(--size) * -1);
    --delay: calc(var(--i) * -0.0335s);
    --duration: 3600ms;
    --ease: cubic-bezier(0.84, 0, 0.2, 1);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .75rem;
    width: var(--size);
    height: var(--size);
    box-shadow: inset rgba(black, 0.15) -0.5rem 0.5rem 1rem;
    transform-style: preserve-3d;
    transform-origin: 50% 100% var(--offset);
    transition-property: transform, background-color;
    transition-timing-function: ease-out;
    animation: animate var(--duration) var(--delay) var(--ease) alternate infinite;
  }

  .cube::before,
  .cube::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    box-shadow: inherit;
    transform-origin: 100% 100%;
  }

  .cube::before {
    transform: translateZ(var(--offset)) rotateY(90deg);
    filter: brightness(120%);
  }

  .cube::after {
    transform: translateZ(var(--offset)) rotateX(-90deg);
    filter: brightness(80%);
  }

  .textContainer {
    position: absolute;
    top: 6rem;
    left: 6rem;
    display: flex;
    flex-direction: column;
    /* color: var(--bgColor1); */
    color: var(--color1);
    font: 1.5rem / 1.25 'Quicksand';
    user-select: none;
  }

  .textContainer p {
    margin: .25rem 0;
  }

  .name {
    display: flex;
    align-items: center;
    gap: 3px;
    /* background: linear-gradient(-45deg, #eeb2a0, #fcbed5, #ace1f5, #b5faea); */
    background: linear-gradient(-45deg, var(--color2), var(--color4), var(--color3));
    background-size: 400% 400%;
    background-clip: text;
    /* color: var(--bgColor2); */
    color: transparent;
    font: 7rem / 1 'Oswald';
    margin: 0;
    animation: gradientShift 10s ease infinite;
  }

  .letter {
    color: var(--color1);
    /* text-shadow: 0 1px 0 rgba(95, 95, 95, 0.5), 0 2px 0 rgba(95, 95, 95, 0.5), 0 3px 0 rgba(95, 95, 95, 0.5), 0 4px 0 rgba(95, 95, 95, 0.5), 0 0 0 rgba(0, 0, 0, 0.3); */
    perspective: 200px;
    transform-origin: 50% 50%;
    transform: translateY(0);
    transition: all 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    /* animation: gradientShift 6s ease infinite; */
  }

  .letter:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60vh;
  }

  /* .letter:hover {
    transform: translateY(-18px);
    text-shadow: 0 1px 0 rgba(95, 95, 95, 0.5), 0 2px 0 rgba(95, 95, 95, 0.5), 0 3px 0 rgba(95, 95, 95, 0.5), 0 4px 0 rgba(95, 95, 95, 0.5), 0 50px 30px rgba(0, 0, 0, 0.3);
    transition: all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  } */

  @keyframes animate {
    0% {
      transform: scaleX(1);
    }
    20% {
      transform: scaleX(0.5) scaleY(0.6) scaleZ(2.5);
    }
    35% {
      transform: scaleX(1) scaleY(0.8) scaleZ(1);
    }
    50% {
      transform: scaleX(.3) scaleY(0.4) scaleZ(4.5);
    }
    80% {
      transform: scaleX(1) scaleZ(1) translateY(calc(var(--size) * 2));
    }
    100% {
      transform: scaleX(1) scaleY(0.5) scaleZ(1) translateY(calc(var(--size) * -2));
    }
  }


  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes pop {
    0% {
      transform: translateY(0);
      transform-origin: 50% 50%;
      text-shadow: 0 1px 0 rgba(95, 95, 95, 0.5), 0 2px 0 rgba(95, 95, 95, 0.5), 0 3px 0 rgba(95, 95, 95, 0.5), 0 4px 0 rgba(95, 95, 95, 0.5), 0 0 0 rgba(0, 0, 0, 0.3);
    }
    100% {
      transform: translateY(-5px);
      transform-origin: 50% 50%;
      text-shadow: 0 1px 0 rgba(95, 95, 95, 0.5), 0 2px 0 rgba(95, 95, 95, 0.5), 0 3px 0 rgba(95, 95, 95, 0.5), 0 4px 0 rgba(95, 95, 95, 0.5), 0 50px 30px rgba(0, 0, 0, 0.3);
    }
  }
</style>                                        
